<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
      <div class="mt-6 flex items-center justify-between px-6">
        <h4>{{ Object.entries(this.data).length === 0 ? $t("AJOUTER_UNE") : $t("MODIFICATION_DE_L") }} {{$t("PRESCRIPTION")}}  </h4>
        <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
      </div>
      <vs-divider class="mb-0"></vs-divider>
      <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
        <div class="p-6">
          <p class="pt-4">{{ $t("entitled") }}<b style="color: #ff6141" >*</b> </p>
          <vs-input
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="entitled"
            v-model="entitled"
            class="w-full" />
          <span class="text-danger text-sm" v-show="errors.has('entitled')">{{$t("entitled_error")}}</span>
          
        <p class="pt-4">{{ $t("durationOfTreatment") }}<b style="color: #ff6141" >*</b> </p>
        <vs-input
          v-validate="'required'"
          data-vv-validate-on="blur"
          name="durationOfTreatment"
          v-model="durationOfTreatment"
          class="w-full" />
        <span class="text-danger text-sm" v-show="errors.has('durationOfTreatment')">{{$t("durationOfTreatment_error")}}</span>
        
        <p class="pt-4">{{ $t("Comment") }}<b style="color: #ff6141" >*</b> </p>
        <vs-textarea
          v-model="comment"
          class="w-full mb-4 " 
          rows="5"
          v-validate="'required'"
          name="comment"
          label="Commentaires"  />
        <span  class="text-danger text-sm" v-show="errors.has('comment')">{{$t("comment_error")}}</span>


        <p class="pt-4"> {{$t("patient")}} <b style="color: #ff6141" >*</b> </p>
           <vs-select
              v-validate="'required'"
              autocomplete
              v-model="patient"
              class="w-full "
              name="patient"
          >
          <vs-select-item :key="item" :value="item.id" :text="item.last_name" v-for="item in patients" />
          </vs-select>
          <span class="text-danger text-sm" v-show="errors.has('patient')">{{$t("patient_error")}}</span>
  
          <p class="pt-4"> {{$t("medecines")}} <b style="color: #ff6141" >*</b> </p>
          <v-select
            multiple 
            :closeOnSelect="false"
            v-validate="'required'"
            name="medecines"
            autocomplete
            label="name"
            :reduce="rep => rep.id"
            class="w-full" 
            v-model="medecines"
            :options="medecines_liste">
          </v-select>
          <span class="text-danger text-sm" v-show="errors.has('medecines')">{{$t("acte_error")}}</span>
  
          
        </div>
      </component>
  
      <div class="flex flex-wrap items-center p-6" slot="footer">
        <vs-button class="mr-6" @click="actes_validate">{{$t("Soumettre")}}</vs-button>
        <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">{{$t("Annuler")}}</vs-button>
      </div>
    </vs-sidebar>
  </template>
  
<script>
  
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    VuePerfectScrollbar
  },
  data () {
    return {
      updateTreatment:false,
      id:'',
      entitled: '',
      durationOfTreatment:'',
      comment:'',
      patient: '',
      medecines:[],
      input :[],
      medecines_save:[],
      medecines_old:[],
      medecines_delete:[],
      patients:[],
      medecines_liste:[],
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      }
    }
  },
  watch: {
    isSidebarActive (val) {
      if (!val) return
      if (Object.entries(this.data).length === 0) {
        this.updateTreatment = false
        this.initValues()
        this.$validator.reset()
      } else {
        this.updateTreatment = true
        this.entitled = this.data.entitled
        this.durationOfTreatment = this.data.durationOfTreatment
        this.comment = this.data.comment
        this.medecines = this.data.medecines
        this.patient = this.data.patient.id
        this.id = this.data.id
        for (let i = 0; i < this.medecines.length; i++) {
          this.medecines_old.push(this.medecines[i].id)
        }
        
      }
    },

    medecines (value) {
      const acte_new = []
      const acte_new_teste = []
      for (let i = 0; i < value.length; i++) {
        acte_new.push(value[i])
        this.medecines_save = acte_new
        if (value[i].id) {
          this.medecines_save.length = 0
          acte_new_teste.push(value[i].id)
          this.medecines_save = acte_new_teste
        }
      }

    }
  },

  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
          // this.$validator.reset()
          // this.initValues()
        }
      }
    },
    scrollbarTag () { return this.$store.getters.scrollbarTag }
  },
  methods: {
    initValues () {
      this.entitled = ''
      this.durationOfTreatment = ''
      this.comment = ''
      this.patient = ''
      this.medecines = []
    },
    actes_validate () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.submitData()
        }
      })
    },
    removeItemAll (arr, value) {
      let i = 0
      while (i < arr.length) {
        if (arr[i] === value) {
          arr.splice(i, 1)
        } else {
          ++i
        }
      }
      return arr
    },
  
    async submitData () {
      this.$vs.loading()
      for (let i = 0; i < this.medecines_save.length; i++) {
        const supprOp = this.medecines_save[i]
        this.removeItemAll(this.medecines_old, supprOp)
      }
      if (this.updateTreatment === false) {
        this.input = {
          entitled : this.entitled,
          durationOfTreatment : this.durationOfTreatment,
          comment : this.comment,
          medecines : this.medecines,
          patient : this.patient
        }
      } else if (this.updateTreatment === true) {
        this.input = {
          id:this.id,
          entitled : this.entitled,
          durationOfTreatment : this.durationOfTreatment,
          comment : this.comment,
          medecines : this.medecines_save,
          patient : this.patient,
          medecines_delete:this.medecines_old
        }
        
      }
      let url = 'prescriptions/'
      let methods = 'post'
      const message = {
        error: this.$t('save_error'),
        success: this.$t('prescriptions_save')
      }
      if (this.input.id) {
        url += `${this.input.id}/`
        methods = 'put'
        message.success = this.$t('prescriptions_update')
      }
  
      this.$http[methods](url, this.input)
        .then((response) => {
          window.prescriptions.getAllprescriptions()
          window.getPrendTaCom.success(message.success, response)
          this.$emit('closeSidebar')
          this.initValues()
        })
        .catch((error) => {
  
          const clefs = Object.keys(error.response.data)
          for (let i = 0; i < clefs.length; i++) {
            const item = clefs[i]
            let libelle = ''
            if (item === 'entitled') {
              libelle = this.$t('entitled')
            }
            if (item === 'durationOfTreatment') {
              libelle = this.$t('durationOfTreatment')
            }
            if (item === 'Comment') {
              libelle = this.$t('Comment')
            }
            if (item === 'patient') {
              libelle = this.$t('patient')
            }
            if (item === 'medecines') {
              libelle = this.$t('medecines')
            }
  
            for (let j = 0; j < error.response.data[item].length; j++) {
              window.getPrendTaCom.error(`${libelle} :  ${error.response.data[item][j]}`)
            }
          }
    
          window.getPrendTaCom.error(message.error)
        })
    }
  },
  async created () {
    this.$http.get('patients/')
      .then((response) => { this.patients = response.data })
      .catch(() => { })
    this.$http.get('products/')
      .then((response) => { this.medecines_liste = response.data })
      .catch(() => { })
  }
}
</script>
  
  <style lang="scss" scoped>
  .add-new-data-sidebar {
    ::v-deep .vs-sidebar--background {
      z-index: 52010;
    }
  
    ::v-deep .vs-sidebar {
      z-index: 52010;
      width: 400px;
      max-width: 90vw;
  
      .img-upload {
        margin-top: 2rem;
  
        .con-img-upload {
          padding: 0;
        }
  
        .con-²-upload {
          width: 100%;
          margin: 0;
        }
      }
    }
  }
  
  .scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
  
    &:not(.ps) {
      overflow-y: auto;
    }
  }
  </style>
  