<template>
  <div id="data-list-list-view" class="data-list-container">
    <h1>{{ $t("prescriptions") }}</h1>
    <br>
    <data-view-sidebar :isSidebarActive="addNewDataSidebar" @closeSidebar="toggleDataSidebar" :data="sidebarData" />
    <vs-table ref="table" pagination :max-items="itemsPerPage" search :data="prescriptions">

      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

        <div class="flex flex-wrap-reverse items-center data-list-btn-container">
          <!-- ADD NEW -->
          <div class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary" @click="addNewData">
            <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
            <span class="ml-2 text-base text-primary">{{ $t("Ajouter") }}</span>
          </div>
        </div>

        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
          <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ prescriptions.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : prescriptions.length }} of {{ queriedItems }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>

            <vs-dropdown-item @click="itemsPerPage=20">
              <span>20</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=40">
              <span>40</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=60">
              <span>60</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=80">
              <span>80</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th sort-key="Reference">{{ $t("Reference") }}</vs-th>
        <vs-th sort-key="doctor">{{ $t("doctor") }}</vs-th>
        <vs-th sort-key="durationOfTreatment">{{ $t("durationOfTreatment") }}</vs-th>
        <vs-th sort-key="patient">{{ $t("patient") }}</vs-th>
        <vs-th sort-key="products">{{ $t("products") }}</vs-th>
        <vs-th sort-key="quantités">{{ $t("quantités") }}</vs-th>
        <vs-th sort-key="posologie">{{ $t("posologie") }}</vs-th>
        <vs-th sort-key="Comment">{{ $t("Comment") }}</vs-th>
       
        <vs-th>Action</vs-th>
      </template>

      <template slot-scope="{data}">
        <tbody>
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>
            <p class="product-name font-medium truncate">{{tr.entitled}}</p>
          </vs-td>
         
          <vs-td>
            <p class="product-name font-medium truncate">Dr.{{tr.doctor.last_name}}</p>
          </vs-td>

          <vs-td>
            <p class="product-name font-medium truncate">{{tr.durationOfTreatment}}</p>
          </vs-td>

          <vs-td>
              <p class="product-name font-medium truncate">{{tr.patient.last_name}}{{'  '}}{{tr.patient.first_name}}</p>
          </vs-td>

          <vs-td>
            <li v-for="items_medecine in tr.medecines" :key="items_medecine" class="product-name font-medium truncate">{{ items_medecine.medecine.name}}</li>
          </vs-td>
          <vs-td>
            <li v-for="items_quantity in tr.medecines" :key="items_quantity" class="product-name font-medium truncate">{{ items_quantity.quantity.quantity}}</li>
          </vs-td>
          <vs-td>
            <li v-for="items_dosage in tr.medecines" :key="items_dosage" class="product-name font-medium truncate">{{ items_dosage.dosage.name}}</li>
          </vs-td>
          <vs-td>
            <p class="product-name font-medium truncate">{{tr.comment}}</p>
          </vs-td>
        
          
          <vs-td class="whitespace-no-wrap">
            <div class="flex">
              <router-link to="/InvoicePrescriptions">
              <vx-tooltip text="Voir" color="primary">
               <feather-icon 
                   style="color: navy " 
                   icon="EyeIcon" 
                   svgClasses="w-5 h-5 hover:text-primary stroke-current"
                    v-on:click="openPlayerDialog(tr)" 
                    color="warring" type="border"
                    class="ml-2" />
                </vx-tooltip>
              </router-link>

              <vx-tooltip :text="$t('Modifier')" color="warning">
                <feather-icon
                  style="color: #FF9F43"
                  icon="EditIcon"
                  svgClasses="w-5 h-5 hover:text-warning stroke-current"
                  class="ml-2"
                  @click="testeEdit(tr)"
                />
              </vx-tooltip>
              <vx-tooltip :text="$t('Supprimer')" color="danger">
                <feather-icon
                  @click="deleteData(tr.id)"
                  style="color: red"
                  icon="TrashIcon"
                  svgClasses="w-5 h-5 hover:text-danger stroke-current"
                  class="ml-2"
                />
              </vx-tooltip>
            </div>
          </vs-td>
        </vs-tr>
        </tbody>
      </template>
    </vs-table>
    <vs-popup
      fullscreen 
      class="calendar-event-dialog"
      :title="this.$t('prescriptions')"
      :active.sync="activePrompt">
<vx-card  no-shadow card-border>
<div class="vx-row ">
        <div class="vx-col w-full">
          <div class="flex items-end px-3">
            <feather-icon color="success" svgClasses="w-6 h-6" icon="UserPlusIcon" class="mr-2" />
            <!-- ICON COULEURE -->
            <!--<vs-avatar color="success" icon-pack="feather" svgClasses="w-6 h-6" class="mr-2" icon="icon-inbox" /> -->
            <span class="font-medium text-lg leading-none">Informations</span>
          </div>
          <vs-divider />
        </div>
      </div>

    <div class="vx-row">
      <div class="vx-col md:w-1/2 sm:w-1/2 w-full">
        <label class="text-sm opacity-75">Patient<b style="color: #ff6141" >*</b></label>
        <vs-select
            v-validate="'required'"
            data-vv-validate-on="blur"
            autocomplete
            v-model="patient"
            class="w-full "
            name="patient"
        >
        <vs-select-item :key="item" :value="item.id" :text="item.last_name" v-for="item in patients" />
        </vs-select>
        <span class="text-danger text-sm" v-show="errors.has('patient')">{{$t("patient_error")}}</span>

      </div>

      <div class="vx-col md:w-1/2 sm:w-1/2 w-full">
        <label class="text-sm opacity-75">Durée de traitement<b style="color: #ff6141" >*</b></label>
        <vs-input
        v-validate="'required'"
        data-vv-validate-on="blur"
        name="durationOfTreatment"
        v-model="durationOfTreatment"
        class="w-full" />
      <span class="text-danger text-sm" v-show="errors.has('durationOfTreatment')">{{$t("durationOfTreatment_error")}}</span>
      
      </div>
    
    </div>

</vx-card>

<vx-card class="mt-10" no-shadow card-border>
<div class="vx-row ">
        <div class="vx-col w-full">
          <div class="flex items-end px-3">
            <feather-icon svgClasses="w-6 h-6" icon="FileTextIcon" class="mr-2" />
            <span class="font-medium text-lg leading-none">Prescriptions</span>
          </div>
          <vs-divider />
        </div>
      </div>
      <div class="vx-row ">
                <vs-checkbox  v-model="add_new_prod" class="vx-col  md:w-1/2 sm:w-1/2 w-full">
                <b style="color: hsl(128, 74%, 32%)" > Voulez-vous ajouter un nouveau produit à la liste des produits?</b>
                </vs-checkbox>
            </div>
    <div v-if="add_new_prod===true" class="vx-row pt-4">
      
      <div v-if="add_new_prod===true" class="vx-col  md:w-1/4 sm:w-1/2 w-full">
        <label v-if="add_new_prod===true" class="text-sm opacity-75">Nom du produit<b style="color: #ff6141" >*</b></label>
        <vs-input
        v-if="add_new_prod===true"
        data-vv-validate-on="blur"
        name="name_prod"
        v-model="name_prod"
        class="w-full" />
      </div>
      <!-- quantité -->
     <!-- <div v-if="add_new_prod===true" class="vx-col  md:w-1/4 sm:w-1/2 w-full">
        <label class="text-sm opacity-75">Quantité<b style="color: #ff6141" >*</b></label>
        <vs-input-number
        v-if="add_new_prod===true"
                    :min="0" 
                    :max="100"
                    name="quantity_prod"
                    data-vv-validate-on="blur"
                    v-model="quantity_prod" 
                    class="w-full "
            />
      </div>-->
      <div v-if="add_new_prod===true" class="vx-col md:w-1/4 sm:w-1/2 w-full ">
        <div class="flex flex-wrap justify-between mt-5 ">
            <vs-button :disabled="!validate_pro_save" color="success" type="filled" icon-pack="feather" icon="icon-plus-circle" @click="submitNewProd()">Ajouter</vs-button>
            
            <vs-button   color="danger" type="filled" icon-pack="feather" icon="icon-minus-circle" @click="resetData_prod">Annuler</vs-button>
          </div>
      </div>
    </div>
      <div class="vx-row pt-4">
      <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
        <label class="text-sm opacity-75">Liste des produits<b style="color: #ff6141" >*</b></label>
        <v-select
          :closeOnSelect="true"
          name="medecines"
          autocomplete
          label="name"
          :reduce="rep => rep"
          class="w-full" 
          v-model="medecine"
          :options="medecines_liste">
        </v-select>

      </div>

      <div class="vx-col  md:w-1/4 sm:w-1/2 w-full">
        <label class="text-sm opacity-75">Quantité<b style="color: #ff6141" >*</b></label>
        <vs-input
        data-vv-validate-on="blur"
        name="quantity"
        v-model="quantity"
        class="w-full" />
              
      </div>
      <div class="vx-col md:w-1/4 sm:w-1/2 w-full ">
        <label class="text-sm opacity-75">Posologie<b style="color: #ff6141" >*</b></label>
        <vs-textarea
        v-model="dosage"
        class="w-full " 
        rows="3"
        name="dosages"
        label="Dosage"  />

      </div>
      <div class="vx-col md:w-1/4 sm:w-1/2 w-full ">
        <div class="flex flex-wrap justify-between mt-5 ">
            <vs-button v-if="!update_produitChek" :disabled="!validate" color="success" type="filled" icon-pack="feather" icon="icon-plus-circle" @click="submit_produit">Ajouter</vs-button>
            
            <vs-button v-if="update_produitChek" :disabled="!validate" color="success" type="filled" icon-pack="feather" icon="icon-plus-circle" @click="updateProduit">Modifier</vs-button>

            <vs-button   color="danger" type="filled" icon-pack="feather" icon="icon-minus-circle" @click="resetData">Annuler</vs-button>
          </div>
      </div>
    </div>
    <div class="vx-row ">
      <vs-table stripe max-items="3" class="w-full  " pagination search :data="produits_computed">
      <template slot="header">
        <h3>
          Liste de la prescription
        </h3>
      </template>
      <template slot="thead">
        <vs-th hidden  >N°</vs-th>
        <vs-th>Produits</vs-th>
        <vs-th>Quantité</vs-th>
        <vs-th>Posologie</vs-th>
        <vs-th>Actions</vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
          <vs-td hidden :data="data[indextr].id">
            {{data[indextr].id}}
          </vs-td>
          <vs-td :data="data[indextr].medecine">
            {{data[indextr].medecine.name}}
          </vs-td>
          <vs-td :data="data[indextr].quantity">
            {{data[indextr].quantity}}
          </vs-td>
          <vs-td :data="data[indextr].dosage">
            {{data[indextr].dosage}}
          </vs-td>

          <vs-td class="whitespace-no-wrap">
            <div class="flex">
              <vs-button @click.stop="update_pro_chek(data[indextr])" color="warning" size="small" type="filled" icon="edit"></vs-button>
              
              <vs-button @click.stop="deleteProduit(data[indextr].id)"  color="danger" size="small" type="filled" class="ml-2" icon="delete"></vs-button>
          
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    </div>
 
</vx-card>

<vx-card class="mt-10" no-shadow card-border>
<div class="vx-row ">
        <div class="vx-col w-full">
          <div class="flex items-end px-3">
            <feather-icon svgClasses="w-6 h-6" icon="PlusSquareIcon" class="mr-2" />
            <span class="font-medium text-lg leading-none">ACTE PARACLINIQUE</span>
          </div>
          <vs-divider />
        </div>
      </div>

    <div class="vx-row">
      <vs-textarea
        v-model="paraclinicalActivities"
        class="w-full" 
        rows="5"
        v-validate="'required'"
        name="paraclinicalActivities"
        label="Acte paraclinique"  />
      <span  class="text-danger text-sm" v-show="errors.has('paraclinicalActivities')">{{$t("paraclinicalActivities_error")}}</span>
    </div>

</vx-card>

<vx-card class="mt-10" no-shadow card-border>
<div class="vx-row ">
        <div class="vx-col w-full">
          <div class="flex items-end px-3">
            <feather-icon svgClasses="w-6 h-6" icon="TagIcon" class="mr-2" />
            <span class="font-medium text-lg leading-none">Commentaire</span>
          </div>
          <vs-divider />
        </div>
      </div>

    <div class="vx-row">
      <vs-textarea
        v-model="comment"
        class="w-full" 
        rows="5"
        v-validate="'required'"
        name="comment"
        label="Commentaire"  />
      <span  class="text-danger text-sm" v-show="errors.has('comment')">{{$t("comment_error")}}</span>
    </div>

</vx-card>
<div class="vx-row">
    <div class="vx-col w-full">
      <div class="mt-8 flex flex-wrap items-center justify-end">
        <vs-button class="ml-auto mt-2" type="filled" @click="actes_validate()" >{{$t("valider")}}</vs-button>
        <vs-button class="ml-4 mt-2" type="filled" color="warning" @click="quit()">{{$t("Quit")}}</vs-button>
      
      </div>
    </div>
  </div>
    </vs-popup>
    <vs-popup
      fullscreen 
      class="calendar-event-dialog"
      title="Modifier une ordonance"
      :active.sync="activePrompt2">
<vx-card  no-shadow card-border>
<div class="vx-row ">
        <div class="vx-col w-full">
          <div class="flex items-end px-3">
            <feather-icon svgClasses="w-6 h-6" icon="UserPlusIcon" class="mr-2" />
            <span class="font-medium text-lg leading-none">Informations</span>
          </div>
          <vs-divider />
        </div>
      </div>

    <div class="vx-row">
      <div class="vx-col md:w-1/2 sm:w-1/2 w-full">
        <label class="text-sm opacity-75">Patient<b style="color: #ff6141" >*</b></label>
        <vs-select
            v-validate="'required'"
            data-vv-validate-on="blur"
            autocomplete
            v-model="patient"
            class="w-full "
            name="patient"
        >
        <vs-select-item :key="item" :value="item.id" :text="item.last_name" v-for="item in patients" />
        </vs-select>
        <span class="text-danger text-sm" v-show="errors.has('patient')">{{$t("patient_error")}}</span>

      </div>

      <div class="vx-col md:w-1/2 sm:w-1/2 w-full">
        <label class="text-sm opacity-75">Durée de traitement<b style="color: #ff6141" >*</b></label>
        <vs-input
        v-validate="'required'"
        data-vv-validate-on="blur"
        name="durationOfTreatment"
        v-model="durationOfTreatment"
        class="w-full" />
      <span class="text-danger text-sm" v-show="errors.has('durationOfTreatment')">{{$t("durationOfTreatment_error")}}</span>
      
      </div>

    
    </div>

</vx-card>

<vx-card class="mt-10" no-shadow card-border>
<div class="vx-row ">
        <div class="vx-col w-full">
          <div class="flex items-end px-3">
            <feather-icon svgClasses="w-6 h-6" icon="FileTextIcon" class="mr-2" />
            <span class="font-medium text-lg leading-none">Prescriptions</span>
          </div>
          <vs-divider />
        </div>
      </div>
      <div class="vx-row ">
                <vs-checkbox  v-model="add_new_prod" class="vx-col  md:w-1/2 sm:w-1/2 w-full">
                <b style="color: hsl(128, 74%, 32%)" > Voulez-vous ajouter un nouveau produit à la liste des produits?</b>
                </vs-checkbox>
            </div>
    <div v-if="add_new_prod===true" class="vx-row pt-4">
      
      <div v-if="add_new_prod===true" class="vx-col  md:w-1/4 sm:w-1/2 w-full">
        <label v-if="add_new_prod===true" class="text-sm opacity-75">Nom du produit<b style="color: #ff6141" >*</b></label>
        <vs-input
        v-if="add_new_prod===true"
        data-vv-validate-on="blur"
        name="name_prod"
        v-model="name_prod"
        class="w-full" />
      </div>
      <!-- quantité 
      <div v-if="add_new_prod===true" class="vx-col  md:w-1/4 sm:w-1/2 w-full">
        <label class="text-sm opacity-75">Quantité<b style="color: #ff6141" >*</b></label>
        <vs-input-number
        v-if="add_new_prod===true"
                    :min="0" 
                    :max="100"
                    name="quantity_prod"
                    data-vv-validate-on="blur"
                    v-model="quantity_prod" 
                    class="w-full "
            />
      </div>-->
      <div v-if="add_new_prod===true" class="vx-col md:w-1/4 sm:w-1/2 w-full ">
        <div class="flex flex-wrap justify-between mt-5 ">
            <vs-button :disabled="!validate_pro_save" color="success" type="filled" icon-pack="feather" icon="icon-plus-circle" @click="submitNewProd()">Ajouter</vs-button>
            
            <vs-button   color="danger" type="filled" icon-pack="feather" icon="icon-minus-circle" @click="resetData_prod">Annuler</vs-button>
          </div>
      </div>
    </div>
      <div class="vx-row pt-4">
      <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
        <label class="text-sm opacity-75">Liste des produits<b style="color: #ff6141" >*</b></label>
        <v-select
          :closeOnSelect="true"
          name="medecines"
          autocomplete
          label="name"
          :reduce="rep => rep"
          class="w-full" 
          v-model="medecine"
          :options="medecines_liste">
        </v-select>

      </div>

      <div class="vx-col  md:w-1/4 sm:w-1/2 w-full">
        <label class="text-sm opacity-75">Quantité<b style="color: #ff6141" >*</b></label>
        <vs-input
        data-vv-validate-on="blur"
        name="quantity"
        v-model="quantity"
        class="w-full" />
              
      </div>
      <div class="vx-col md:w-1/4 sm:w-1/2 w-full ">
        <label class="text-sm opacity-75">Posologie<b style="color: #ff6141" >*</b></label>
        <vs-textarea
        v-model="dosage"
        class="w-full " 
        rows="3"
        name="dosages"
        label="Dosage"  />

      </div>
      <div class="vx-col md:w-1/4 sm:w-1/2 w-full ">
        <div class="flex flex-wrap justify-between mt-5 ">
            <vs-button v-if="!update_produitChek" :disabled="!validate" color="success" type="filled" icon-pack="feather" icon="icon-plus-circle" @click="submit_produit">Ajouter</vs-button>
            
            <vs-button v-if="update_produitChek" :disabled="!validate" color="success" type="filled" icon-pack="feather" icon="icon-plus-circle" @click="updateProduit">Modifier</vs-button>

            <vs-button   color="danger" type="filled" icon-pack="feather" icon="icon-minus-circle" @click="resetData">Annuler</vs-button>
          </div>
      </div>
    </div>
    <div class="vx-row ">
      <vs-table stripe max-items="3" class="w-full  " pagination search :data="produits_computed">
      <template slot="header">
        <h3>
          Liste de la prescription
        </h3>
      </template>
      <template slot="thead">
        <vs-th hidden >N°</vs-th>
        <vs-th>Produits</vs-th>
        <vs-th>Quantité</vs-th>
        <vs-th>Posologie</vs-th>
        <vs-th>Actions</vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
          <vs-td hidden :data="data[indextr].id">
            {{data[indextr].id}}
          </vs-td>
          <vs-td :data="data[indextr].medecine">
            {{data[indextr].medecine.name}}
          </vs-td>
          <vs-td :data="data[indextr].quantity">
            {{data[indextr].quantity}}
          </vs-td>
          <vs-td :data="data[indextr].dosage">
            {{data[indextr].dosage}}
          </vs-td>

          <vs-td class="whitespace-no-wrap">
            <div class="flex">
              <vs-button @click.stop="update_pro_chek(data[indextr])" color="warning" size="small" type="filled" icon="edit"></vs-button>
              
              <vs-button @click.stop="deleteProduit(data[indextr].id)"  color="danger" size="small" type="filled" class="ml-2" icon="delete"></vs-button>
          
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    </div>
 
</vx-card>

<vx-card class="mt-10" no-shadow card-border>
<div class="vx-row ">
        <div class="vx-col w-full">
          <div class="flex items-end px-3">
            <feather-icon svgClasses="w-6 h-6" icon="PlusSquareIcon" class="mr-2" />
            <span class="font-medium text-lg leading-none">ACTE PARACLINIQUE</span>
          </div>
          <vs-divider />
        </div>
      </div>

    <div class="vx-row">
      <vs-textarea
        v-model="paraclinicalActivities"
        class="w-full" 
        rows="5"
        v-validate="'required'"
        name="paraclinicalActivities"
        label="Acte paraclinique"  />
      <span  class="text-danger text-sm" v-show="errors.has('paraclinicalActivities')">{{$t("paraclinicalActivities_error")}}</span>
    </div>

</vx-card>

<vx-card class="mt-10" no-shadow card-border>
<div class="vx-row ">
        <div class="vx-col w-full">
          <div class="flex items-end px-3">
            <feather-icon svgClasses="w-6 h-6" icon="TagIcon" class="mr-2" />
            <span class="font-medium text-lg leading-none">Commentaire</span>
          </div>
          <vs-divider />
        </div>
      </div>

    <div class="vx-row">
      <vs-textarea
        v-model="comment"
        class="w-full" 
        rows="5"
        v-validate="'required'"
        name="comment"
        label="Commentaire"  />
      <span  class="text-danger text-sm" v-show="errors.has('comment')">{{$t("comment_error")}}</span>
    </div>

</vx-card>
<div class="vx-row">
    <div class="vx-col w-full">
      <div class="mt-8 flex flex-wrap items-center justify-end">
        <vs-button class="ml-auto mt-2" type="filled" @click="actes_validate()" >{{$t("Modifier")}}</vs-button>
        <vs-button class="ml-4 mt-2" type="filled" color="warning" @click="quit()">{{$t("Quit")}}</vs-button>
      
      </div>
    </div>
  </div>
    </vs-popup>
  </div>
</template>
<script>

import DataViewSidebar from './DataViewprescriptions.vue'
import modulePrescription from '@/store/prescription/modulePrescription.js'


export default {
  components: {
    DataViewSidebar
  },
  data () {
    return {
      stockType:'',
      stockTypes:[],
      name_prod:'',
      quantity_prod:1,
      add_new_prod: false,
      update_produitChek : false,

      Has_Med_Dos_Qty_update : false,

      repProduit:[],

      produits_update:[],

      produit_submit:[],

      medecine:'',
      dosage:'',
      quantity:'',

      medecine_id:[],
      dosage_id:[],
      quantity_id:[],

      produits_store:[],

      old_produitID:[],

      New_produitID:[],

      produits:[],
      products:[],
      
      id:'',
      update_HasId:'',
      paraclinicalActivities: '',
      durationOfTreatment:'',
      comment:'',
      patient: '',
      medecines:[],
      input :[],

      medecines_save:[],
      medecines_old:[],
      medecines_delete:[],

      patients:[],
      medecines_liste:[],
      prescriptions: [],

      produits_computed:[],

      id_produit:1,  

      itemIndexProduit:-1,
      
      selected: [],
      itemsPerPage: 20,
      isMounted: false,
      addNewDataSidebar: false,
      activePrompt:false,
      activePrompt2:false,
      sidebarData: {}
    }
  },
  computed: {
    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    Produits () {
      
      return this.$store.state.prescription.produits 
    },
    Produits_Results () {
      
      return this.produits_computed 
    },

    validate () {
      if (
        (
          this.medecine !== ''
              && this.dosage !== ''
              && this.quantity !== ''
     
        )) return true
    },

    validate_pro_save () {
      if (
        (
          this.name_prod !== ''
              && this.stockType !== ''
              && this.quantity_prod !== '' && this.quantity_prod > 0
     
        )) return true
    },
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.prescriptions.length
    }
  },
  

  methods: {
    submitNewProd () {
      const input = {
        name: this.name_prod,
        stockType: this.stockType,
        quantity:this.quantity_prod
      }
      this.$vs.loading()
      this.$http.post('stocks/', input)
        .then(() => {
          this.getAllproducts()
          this.$vs.notify({
            color:'success',
            icon:'done',
            text:'Produit créer avec succès',
            position:'top-right'
          })
          this.resetData_prod()
          this.$vs.loading.close()
        })
        .catch(() => {
          window.getPrendTaCom.error('Enrégistrement échoué')
          this.$vs.notify({
            color:'warning',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            time:15000,
            text:'Ce produit est déjà créé',
            position:'top-center'
          })
          this.$vs.loading.close()
        })

    },
    resetData_prod () {
      this.name_prod = ''
      this.quantity_prod = 1
      
    },
    resetData () {
      this.id = ''
      this.medecine = ''
      this.quantity = ''
      this.dosage = ''
      this.update_produitChek = false
    },
  
    submit_produit () {
      
      this.produit_submit.length = 0
      const obj = {
        id: this.id_produit++,
        quantity: this.quantity,
        medecine: this.medecine,
        dosage: this.dosage
      }
      this.produit_submit = obj
      this.FIND_ITEM_PRODUIT(this.produits_computed, this.produit_submit)
      //this.produits_computed.unshift(this.produit_submit)
      if (this.itemIndexProduit === -1) {
        this.ADD_ITEM_PRODUIT(this.produits_computed, this.produit_submit)
        //this.$store.dispatch('prescription/addItem', this.produit_submit)
        this.$vs.notify({
          color:'success',
          icon:'done',
          text:'Produit ajouté à la liste',
          position:'top-right'
        })
        this.resetData()
      } else if (this.itemIndexProduit !== -1) {
        this.$vs.notify({
          color:'warning',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          time:15000,
          text:'Produit existe déjà dans la liste',
          position:'top-center'
        })
      }
     
    },
    deleteProduit (id) {
      //const supprOp = id
      //this.removeItemAll(this.produits_computed, supprOp)
      this.REMOVE_ITEM_PRODUIT(this.produits_computed, id)
      //this.$store.dispatch('prescription/removeItem', id)
      this.$vs.notify({
        color:'success',
        icon:'delete',
        text:'Produit supprimé de la liste',
        position:'top-right'
      })
    
    },
    update_pro_chek (prod) {
      this.id = prod.id
      this.medecine = prod.medecine
      this.quantity = prod.quantity
      this.dosage = prod.dosage
      this.update_produitChek = true
    },
  
    updateProduit () {
      this.load()
      const obj = {
        id: this.id,
        medecine: this.medecine,
        quantity: this.quantity,
        dosage: this.dosage
      }
      //this.$store.dispatch('prescription/updateItem', obj)
      this.UPDATE_ITEM_PRODUIT(this.produits_computed, obj)
      this.acceptAlert()
      this.resetData()
    },
    load () {
      this.$vs.loading()
      setTimeout(() => {
        this.$vs.loading.close()
      }, 500)
    },
    acceptAlert () {
      this.$vs.notify({
        color:'success',
        icon:'edit',
        text:'Produit modifié',
        position:'top-right'
      })
    },

    initValues () {
      this.durationOfTreatment = ''
      this.comment = ''
      this.patient = ''
      this.paraclinicalActivities = ''
      this.dosages = []
      this.quantities = []
      this.medecines = []
      this.medecine_id = []
      this.dosage_id = []
      this.quantity_id = []
      this.medecines_old = []
      this.old_produitID = []
      this.update_HasId = ''
      this.produits_computed = []
      this.id_produit =  ''
      //this.$store.dispatch('prescription/initItem')
    //this.activePrompt = false
    },
    quit () {
      this.durationOfTreatment = ''
      this.comment = ''
      this.patient = ''
      this.paraclinicalActivities = ''
      this.dosages = []
      this.quantities = []
      this.medecines = []
      this.medecine_id = []
      this.dosage_id = []
      this.quantity_id = []
      this.medecines_old = []
      this.old_produitID = []
      this.update_HasId = ''
      //this.$store.dispatch('prescription/initItem')
      this.produits_computed = []
      this.id_produit =  ''
      this.activePrompt = false
      this.activePrompt2 = false

    },

    actes_validate () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.submitData()
        }
      })
    },
    addNewData () {
      //this.sidebarData = {}
      this.activePrompt = true
      this.initValues()
      //this.$store.dispatch('prescription/initItem')
    //this.toggleDataSidebar(true)
    },
    openPlayerDialog (player) {
      this.$store.dispatch('prescription/addprescriprion_invoices', player)

    },
    removeItemAll (arr, value) {
      let i = 0
      while (i < arr.length) {
        if (arr[i] === value) {
          arr.splice(i, 1)
        } else {
          ++i
        }
      }
      return arr
    },
    ADD_ITEM_PRODUIT (produits, produit) {
      produits.unshift(produit)
    },
    REMOVE_ITEM_PRODUIT (produits, produitId) {
      const ItemIndex = produits.findIndex((p) => p.id === produitId)
      produits.splice(ItemIndex, 1)
    },
    UPDATE_ITEM_PRODUIT (produits, produit) {
      const itemIndex = produits.findIndex((p) => p.id === produit.id)
      Object.assign(produits[itemIndex], produit)
    },
    FIND_ITEM_PRODUIT (produits, produit) {
      this.itemIndexProduit = produits.findIndex((p) => p.medecine.id === produit.medecine.id)
    },
    deleteData (id) {
      const base_self = this
      this.$vs.dialog({
        type:'confirm',
        color: 'danger',
        title: 'Confirmation',
        text: this.$t('enteteDelete'),
        acceptText: this.$t('Confirmer'),
        cancelText: this.$t('Annuler'),
        accept: async () => {
          this.$vs.loading()
          this.$http.delete(`prescriptions/${id}/`)
            .then(response => {
              base_self.getAllprescriptions()
              window.getPrendTaCom.success(this.$t('Suppressionprescriptions'), response)
            })
            .catch(() => {
              window.getPrendTaCom.error({ message: this.$t('Suppression') })
            })
        }
      })

    },

    testeEdit (ord) {
      this.Has_Med_Dos_Qty_update = true
      this.id_produit =  ''
      this.produits_computed = []
      this.repProduit.length = 0
      this.update_HasId = ord.id
      this.paraclinicalActivities = ord.paraclinicalActivities
      this.durationOfTreatment = ord.durationOfTreatment
      this.comment = ord.comment
      this.patient = ord.patient.id
      for (let i = 0; i < ord.medecines.length; i++) {
        const obj = {
          id: '',
          medecine: '',
          quantity: '',
          dosage: ''
        }
        obj.id = this.id_produit++
        obj.medecine = ord.medecines[i].medecine
        obj.dosage = ord.medecines[i].dosage.name
        obj.quantity = ord.medecines[i].quantity.quantity
        this.old_produitID.push(ord.medecines[i].id)
        this.repProduit.push(obj)
        //this.$store.dispatch('prescription/addItemUpdate', obj)

      }
      for (let j = 0; j <  this.repProduit.length; j++) {
        //this.$store.dispatch('prescription/addItemUpdate', this.repProduit[j])
        this.ADD_ITEM_PRODUIT(this.produits_computed, this.repProduit[j])
      }
      this.activePrompt2 = true

    },

    saveEditTeste () {
      const produits_store = this.produits_computed
      for (let i = 0; i < produits_store.length; i++) {
        this.New_produitID.push(produits_store[i].id)
      }
      for (let i = 0; i < this.New_produitID.length; i++) {
        const supprOp = this.New_produitID[i]
        this.removeItemAll(this.old_produitID, supprOp)
      }

    },

    testeSubmited () {
      this.saveEditTeste()
      const produits_store = this.produits_computed
      for (let i = 0; i < produits_store.length; i++) {
        this.medecine_id.push(produits_store[i].medecine.id)
        this.dosage_id.push(produits_store[i].dosage)
        this.quantity_id.push(produits_store[i].quantity)
      }

      this.input = {
        id:this.update_HasId,
        durationOfTreatment : this.durationOfTreatment,
        comment : this.comment,
        dosages : this.dosage_id,
        quantities : this.quantity_id,
        medecines : this.medecine_id,
        paraclinicalActivities : this.paraclinicalActivities,
        medecineHasDosageHasQuantity: this.old_produitID,
        patient : this.patient
      }
    },
    submitData () {
      //this.$vs.loading()
      const produits_store = this.produits_computed
      for (let i = 0; i < produits_store.length; i++) {
        this.medecine_id.push(produits_store[i].medecine.id)
        this.dosage_id.push(produits_store[i].dosage)
        this.quantity_id.push(produits_store[i].quantity)
      }
   
      if (this.Has_Med_Dos_Qty_update === false) {
        this.input = {
          durationOfTreatment : this.durationOfTreatment,
          comment : this.comment,
          dosages : this.dosage_id,
          quantities : this.quantity_id,
          medecines : this.medecine_id,
          paraclinicalActivities : this.paraclinicalActivities,
          patient : this.patient
        }
      } else if (this.Has_Med_Dos_Qty_update === true) {
        this.input = {
          id:this.update_HasId,
          durationOfTreatment : this.durationOfTreatment,
          comment : this.comment,
          dosages : this.dosage_id,
          quantities : this.quantity_id,
          medecines : this.medecine_id,
          paraclinicalActivities : this.paraclinicalActivities,
          medecineHasDosageHasQuantity: this.old_produitID,
          patient : this.patient
        }
      
      }
      let url = 'prescriptions/'
      let methods = 'post'
      const message = {
        error: this.$t('save_error'),
        success: this.$t('prescriptions_save')
      }
      if (this.input.id) {
        url += `${this.input.id}/`
        methods = 'put'
        message.success = this.$t('prescriptions_update')
      }

      if (produits_store.length !== 0) {
        this.$vs.loading()
        this.$http[methods](url, this.input)
          .then((response) => {
            window.prescriptions.getAllprescriptions()
            window.getPrendTaCom.success(message.success, response)
            this.$emit('closeSidebar')
            this.$store.dispatch('prescription/initItem')
            this.quit()
          })
          .catch((error) => {

            const clefs = Object.keys(error.response.data)
            for (let i = 0; i < clefs.length; i++) {
              const item = clefs[i]
              let libelle = ''
         
              if (item === 'durationOfTreatment') {
                libelle = this.$t('durationOfTreatment')
              }
              if (item === 'comment') {
                libelle = this.$t('Comment')
              }
              if (item === 'patient') {
                libelle = this.$t('patient')
              }
              if (item === 'medecines') {
                libelle = this.$t('medecines')
              }
              if (item === 'quantities') {
                libelle = this.$t('quantities')
              }
              if (item === 'dosages') {
                libelle = this.$t('dosages')
              }
              if (item === 'paraclinicalActivities') {
                libelle = this.$t('paraclinicalActivities')
              }
              if (item === 'medecineHasDosageHasQuantity') {
                libelle = this.$t('medecineHasDosageHasQuantity')
              }
              for (let j = 0; j < error.response.data[item].length; j++) {
                window.getPrendTaCom.error(`${libelle} :  ${error.response.data[item][j]}`)
              }
            }
  
            window.getPrendTaCom.error(message.error)
          })
      } else {
        this.$vs.notify({
          color:'warning',
          icon:'info',
          text:'liste des produits vide',
          time:15000,
          position:'top-center'
        })
        this.$vs.loading.close()
      }
    },

    editData (data) {
      this.sidebarData = data
      //this.toggleDataSidebar(true)
    },

    toggleDataSidebar (val = false) {
      this.addNewDataSidebar = val
    },
  
    removeItemAllA (arr, value) {
      let i = 0
      while (i < arr.length) {
        if (arr[i] === value) {
          arr.splice(i, 1)
        } else {
          ++i
        }
      }
      return arr
    },
    getAllprescriptions () {
      this.prescriptions.length = 0
      this.$vs.loading()
      this.$http.get('prescriptions/')
        .then((response) => {
          const prescriptions = response.data
          prescriptions.forEach(prescriptions => {
            this.prescriptions.push({
              id:prescriptions.id,
              entitled: prescriptions.entitled,
              durationOfTreatment: prescriptions.durationOfTreatment,
              comment: prescriptions.comment,
              patient:prescriptions.patient,
              medecines: prescriptions.medecineHasDosageHasQuantity,
              paraclinicalActivities:prescriptions.paraclinicalActivities,
              doctor:prescriptions.doctor
            })

          })
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    },

    getAllproducts () {
      this.$http.get('products/')
        .then((response) => { 
          this.medecines_liste = response.data 
          this.stockType = response.data[0].stockType.id
        })
    }
 
  },
  async created () {
    window.prescriptions = this
    this.$store.registerModule('prescription', modulePrescription)
    this.getAllprescriptions()
    this.getAllproducts()
    this.$http.get('patients/')
      .then((response) => { this.patients = response.data })
      .catch(() => { })
    document.title = 'MooziMed'
  },
  mounted () {
    this.isMounted = true
  }
}
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search{
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }
    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr{
        box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
        td{
          padding: 20px;
          &:first-child{
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }
          &:last-child{
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }
        td.td-check{
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead{
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text{
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check{
        padding: 0 15px !important;
      }
      tr{
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>


<style scoped>

</style>

<style scoped>

</style>
